import { MaxWidth, Rectangle, Typography } from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Icon from '../../icon';
import { Trans } from '../../../lib/i18n';
import HomepageSponsor from './sponsor';

const ScrollControl = styled.div`
  position: absolute;
  height: 100px;
  top: 0;
  width: 50px;
  padding: 0 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  z-index: 10;
  ${(props) => {
    const fade = `
      position: absolute;
      content: ' ';
      top: 0;
      width: 100px;
      height: 100%;
    `;
    if (props.isLeft) {
      return `
        left: 0;
        padding-right: 10px;
        &::after {
          ${fade}
          left: 100px;
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0) 100%
          );
        }
      `;
    }
    if (props.isRight) {
      return `
        right: 0;
        padding-left: 10px;
        &::before {
          ${fade}
          right: 100px;
          background-image: linear-gradient(
            to left,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0) 100%
          );
        }
      `;
    }
    return '';
  }};
`;

const ScrollButton = styled.div`
  border: 2px solid black;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const HomepageSponsorsDesktop = ({ sponsors }) => {
  const ref = useRef(null);
  const containerRef = useRef(null);
  const [showScroll, setShowScroll] = useState(false);
  const [translateX, setTranslateX] = useState(0);
  const [scrollSponsors, setScrollSponsors] = useState(sponsors);
  const [loopNumber, setLoopNumber] = useState(1);

  useEffect(() => {
    const visibleWidth = containerRef?.current?.clientWidth;
    const divWidth = ref?.current?.clientWidth;

    // If sponsors are bigger than the visible width (minus the scroll buttons)
    // show the scroll buttons
    if (!visibleWidth || !divWidth) return;
    if (divWidth > visibleWidth - 200) {
      setShowScroll(true);
    }
  }, []);

  const loopSponsors = () => {
    // We want to add copies of the original sponsors to either end of the current
    // in order to preserve our translation position of the centre. Each sponsor also
    // needs a unique key for react to keep track of it correctly, so we use the loop number
    const additionalSponsorsLeft = sponsors.map((sponsor) => ({
      key: `${sponsor.id}-${loopNumber}L`,
      ...sponsor,
    }));
    const additionalSponsorsRight = sponsors.map((sponsor) => ({
      key: `${sponsor.id}-${loopNumber}R`,
      ...sponsor,
    }));
    setLoopNumber(loopNumber + 1);
    setScrollSponsors([...additionalSponsorsLeft, ...scrollSponsors, ...additionalSponsorsRight]);
  };

  return (
    <Rectangle fill="#fff" component="div" style={{ width: '100%', padding: '50px 0' }}>
      <MaxWidth maxWidth={1600} center>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            width: '100%',
            backgroundColor: '#fff',
            padding: '20px 0',
            overflow: 'hidden',
          }}
        >
          <Typography preset="title" size={18}>
            <Trans i18nKey="common:club_sponsors">Club Sponsors</Trans>
          </Typography>
          <div
            ref={containerRef}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
              height: '90px',
            }}
          >
            {showScroll && (
              <ScrollControl isLeft>
                <ScrollButton
                  onClick={() => {
                    const newTranslateX = translateX + 500;
                    const threshold = ref.current.clientWidth / 2 - 500;
                    // If we're about to go past threshold, loop the sponsors
                    if (newTranslateX > threshold) {
                      loopSponsors();
                    }
                    setTranslateX(newTranslateX);
                  }}
                >
                  <Icon name="arrow-left" size={18} />
                </ScrollButton>
              </ScrollControl>
            )}
            <div
              ref={ref}
              style={{
                flex: 1,
                display: 'flex',
                gap: '40px',
                height: '100%',
                padding: '10px 0',
                alignItems: 'center',
                transition: 'all 0.5s ease-in-out',
                position: 'absolute',
                transform: `translateX(${translateX}px)`,
              }}
            >
              {scrollSponsors.map((sponsor) => (
                <div key={`${sponsor.key || sponsor.id}`} style={{ width: '160px' }}>
                  <HomepageSponsor {...sponsor} />
                </div>
              ))}
            </div>
            {showScroll && (
              <ScrollControl isRight>
                <ScrollButton
                  onClick={() => {
                    const newTranslateX = translateX - 500;
                    const threshold = -(ref.current.clientWidth / 2 - 500);
                    // If we're about go past the threshold, loop the sponsors
                    if (newTranslateX < threshold) {
                      loopSponsors();
                    }
                    setTranslateX(newTranslateX);
                  }}
                >
                  <Icon name="arrow-right" size={18} />
                </ScrollButton>
              </ScrollControl>
            )}
          </div>
        </div>
      </MaxWidth>
    </Rectangle>
  );
};

HomepageSponsorsDesktop.propTypes = {
  sponsors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
};

export default HomepageSponsorsDesktop;
