import { Cushion, FlexContainer, Grid, MaxWidth, Rectangle, Typography } from '@pitchero/react-ui';
import dynamic from 'next/dynamic';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ClubLink from '../../club-link';
import { Trans } from '../../../lib/i18n';
import useBreakpoint from '../../../lib/use-breakpoint';
import CLUB_PROP_TYPES from '../../../lib/prop-types/club';
import MoreLink from '../more-link';
import Item from './item';

const Slider = dynamic(() => import('./slider'), { ssr: false });

const ScrollContainer = styled.div`
  overflow: scroll;
  scrollbar-width: none;
  padding: 0 10px;

  &::-webkit-scrollbar {
    background: transparent;
    width: 0px;
  }
`;

const HomepageMedia = ({ albums, videos, club, style, showMoreLink }) => {
  const containerRef = useRef(null);
  const scrollRef = useRef(null);
  const media = orderBy([...albums, ...videos], ['published'], 'desc');
  const isMobile = useBreakpoint();

  const sliderIsSupported = typeof window !== 'undefined' && 'ResizeObserver' in window;
  const sliderMin = 0;
  const [sliderMax, setSliderMax] = useState(1000); // Will be changed in useEffect
  const [sliderValue, setSliderValue] = useState(0);

  useEffect(() => {
    if (!scrollRef.current) return;
    // Get the max width of the media carousel for the slider
    const maxScrollWidth = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
    setSliderMax(maxScrollWidth);
  }, []);

  if (media.length === 0) {
    return null;
  }

  const handleScroll = (event) => {
    // Carousel scrolled, so update the slider
    const currentLeft = event.currentTarget.scrollLeft;
    setSliderValue(currentLeft);
  };

  const handleSliderChange = (value) => {
    // Slider dragged, so update the carousel
    if (!scrollRef.current) return;
    scrollRef.current.scrollLeft = value;
  };

  return (
    <Rectangle fill="#000" style={{ width: '100%', ...style }} component="div">
      <MaxWidth maxWidth={1366} component="div" center>
        <div ref={containerRef}>
          <Rectangle fill="#000" style={{ width: '100%' }} component="div">
            <Cushion vertical="large" component="div">
              <FlexContainer justifyContent="space-between">
                <Typography preset="title" color="#fff" style={{ paddingLeft: '10px' }}>
                  <Trans i18nKey="media:videos_and_photos">Videos & Photos</Trans>
                </Typography>
                {!isMobile && (
                  <ClubLink route="media.albums">
                    <a>
                      <Rectangle
                        fill="primary"
                        style={{
                          cursor: 'pointer',
                          padding: '12px 18px',
                          borderRadius: '10px',
                          marginRight: '10px',
                        }}
                      >
                        <Typography preset="title" color="primaryContrast" size={16}>
                          <Trans i18nKey="media:see_all">See All</Trans>
                        </Typography>
                      </Rectangle>
                    </a>
                  </ClubLink>
                )}
              </FlexContainer>
            </Cushion>

            <ScrollContainer ref={scrollRef} onScroll={handleScroll}>
              <Grid
                columns={`repeat(${media.length}, max(40%, ${isMobile ? '250' : '425'}px))`}
                rows="1fr"
                columnGap="10px"
                style={{ paddingBottom: '30px' }}
              >
                {media.map((item) => (
                  <Item key={item.id} message={item} containerRef={containerRef} />
                ))}
              </Grid>
            </ScrollContainer>

            {isMobile && showMoreLink && (
              <Cushion bottom="large" horizontal="medium" component="div">
                <MoreLink club={club} route="media.albums">
                  <Trans i18nKey="common:view_all_media">View all</Trans>
                </MoreLink>
              </Cushion>
            )}
            {!isMobile && sliderIsSupported && (
              <Slider
                value={sliderValue}
                min={sliderMin}
                max={sliderMax}
                onChange={handleSliderChange}
              />
            )}
          </Rectangle>
        </div>
      </MaxWidth>
    </Rectangle>
  );
};

HomepageMedia.propTypes = {
  albums: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  videos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  club: CLUB_PROP_TYPES.isRequired,
  style: PropTypes.shape({}),
  showMoreLink: PropTypes.bool,
};

HomepageMedia.defaultProps = {
  style: {},
  showMoreLink: true,
};

export default HomepageMedia;
