import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, MaxWidth, Rectangle } from '@pitchero/react-ui';
import CLUB_PROP_TYPES from '../../../lib/prop-types/club';
import ActivityStreamMessage from '../../activity-stream/message';

const FeaturedContent = ({ club, message }) => {
  if (!message) return null;
  return (
    <Rectangle fill="primary" component="div">
      <Cushion
        responsive={[{ minWidth: 'fullHeader', props: { horizontal: 'small' } }]}
        component="div"
      >
        <MaxWidth maxWidth={1366} center>
          <ActivityStreamMessage
            message={message}
            stacked="desk"
            club={club}
            isHero
            imageProps={{
              alignment: 'top',
              width: 600,
              height: 336,
              transformation: 'square',
            }}
          />
        </MaxWidth>
      </Cushion>
    </Rectangle>
  );
};

FeaturedContent.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  message: PropTypes.shape({}).isRequired,
};

export default FeaturedContent;
