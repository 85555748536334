import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BasicImage from '../../basic-image';

const HomepageSponsorImage = styled(BasicImage)`
  cursor: pointer;
  max-width: 100%;
`;

const HomepageSponsor = ({ image, title, type, url }) => {
  const wrapWithLink = (content) => {
    if (!url) {
      return <div style={{ display: 'inline-block' }}>{content}</div>;
    }

    return (
      <a
        href={url}
        style={{ display: 'inline-block' }}
        target="_blank"
        rel="noopener noreferrer"
        title={type ? `${type} - ${title}` : title}
      >
        {content}
      </a>
    );
  };

  return wrapWithLink(
    <HomepageSponsorImage
      alt={type ? `${type} - ${title}` : title}
      src={image}
      priority="low"
      backgroundColor="ffffff"
      transformation="frame"
      style={{ height: 68 }}
      height={68}
      width={160}
    />,
  );
};

HomepageSponsor.defaultProps = {
  type: null,
  url: null,
};

HomepageSponsor.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  url: PropTypes.string,
};

export default HomepageSponsor;
